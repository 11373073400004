<template>
    <div class="cookie-consent" data-pagearea="cookies_bar">
        <div class="page-container">
            <div class="relative flex flex-col justify-center flex-1">
                <div>
                    <div class="flex gap-10">
                        <AtomsImagesIcon icon="checked-shield-circle" class="hidden text-[7rem] desktop:inline-block" />
                        <div class="flex-1">
                            <div class="flex items-center gap-4">
                                <AtomsImagesIcon icon="checked-shield-circle" class="text-[3rem] mb-4 tablet:hidden" />
                                <div class="mb-4 text-2xl font-bold tracking-normal">{{ $t('Vaše soukromí je pro nás prioritou') }}</div>
                            </div>
                            <div v-html="$t('Tento web používá cookies, aby vám poskytl nejlepší možný zážitek. Kliknutím na Rozumím nám umožníte personalizovat obsah a zlepšit funkčnost webu. Bez vašeho souhlasu nebudeme schopni plně přizpůsobit obsah vašim potřebám, což může ovlivnit některé části e-shopu (například YouTube, Inspirace a další). Pro více informací a správu vašich preferencí klikněte na Nastavení.<br />Safety Agency, s.r.o., IČ: 287 85 606')"></div>
                        </div>
                    </div>
                </div>
                <div class="cookie-consent__settings" :class="{ 'cookie-consent__settings--is-open': showSettings }">
                    <label class="block">
                        <div class="flex">
                            <AtomsFormsCheckbox class="opacity-40" :display-as-switch="true" v-model="cookie0" :disabled="true" />
                            <strong class="text-lg">{{ $t('Funkční') }}</strong>
                        </div>
                        <div class="ml-14">{{ $t('Bez nich by náš web vůbec nefungoval. U těchto cookies není možné zakázat jejich ukládání.') }}</div>
                    </label>
                    <label class="block">
                        <div class="flex">
                            <AtomsFormsCheckbox :display-as-switch="true" v-model="cookie1" @input="cookie1 = $event.target.checked" />
                            <strong class="text-lg">{{ $t('Analytické') }}</strong>
                        </div>
                        <div class="ml-14">{{ $t('Do těchto cookies se anonymně ukládá, jakým způsobem procházíte a používáte náš web. Pomáhají nám lépe chápat, co se našim zákazníkům líbí a kterým směrem se máme ubírat.') }}</div>
                    </label>
                    <label class="block">
                        <div class="flex">
                            <AtomsFormsCheckbox :display-as-switch="true" v-model="cookie2" @input="cookie2 = $event.target.checked" />
                            <strong class="text-lg">{{ $t('Marketingové') }}</strong>
                        </div>
                        <div class="ml-14">{{ $t('Tyto cookies nám pomáhají optimalizovat reklamu směřující na náš e-shop, aby byla co nejvíce efektivní a náš obchod se mohl neustále rozvíjet a zlepšovat.') }}</div>
                    </label>
                    <label class="block">
                        <div class="flex">
                            <AtomsFormsCheckbox :display-as-switch="true" v-model="cookie3" @input="cookie3 = $event.target.checked" />
                            <strong class="text-lg">{{ $t('Personalizované') }}</strong>
                        </div>
                        <div class="ml-14">{{ $t('Díky těmto cookies dokážeme reklamu personalizovat a nabízet vám skutečně jen ty produkty, o které můžete mít zájem.') }}</div>
                    </label>
                </div>
            </div>

            <div class="flex flex-col items-center justify-center gap-3 pt-4 tablet:py-6 desktop:justify-end w-full tablet:w-[200px] desktop:w-[250px]">
                <template v-if="!showSettings">
                    <AtomsImagesIcon icon="checked-shield-circle" class="hidden text-[7rem] mb-8 tablet:inline-block desktop:hidden" />
                    <MoleculesButtonsButton class="w-full" @click="saveConsent(7)">{{ $t('Rozumím') }}</MoleculesButtonsButton>
                    <MoleculesButtonsButton class="w-full" :ghost="true" @click="showSettings = true">{{ $t('Nastavení') }}</MoleculesButtonsButton>
                    <button @click="saveConsent(0)" class="font-bold text-primary-500">{{ $t('Odmítnout vše') }}</button>
                </template>
                <template v-else>
                    <MoleculesContainersSlideToggle :expanded="showSettings" class="hidden tablet:block">
                        <AtomsImagesIcon icon="checked-shield-circle" class="text-[7rem] mb-8" />
                    </MoleculesContainersSlideToggle>
                    <AtomsTextLink :to="cache.getPageUrl(21913)" class="font-bold text-primary-500">{{ $t('Více informací o cookies') }}</AtomsTextLink>
                    <MoleculesButtonsButton class="w-full" :ghost="true" @click="saveConsent(7)">{{ $t('Přijmout vše') }}</MoleculesButtonsButton>
                    <MoleculesButtonsButton class="w-full" @click="saveSettings()" v-if="showSettings">{{ $t('Uložit nastavení') }}</MoleculesButtonsButton>
                </template>
            </div>
        </div>
    </div>
</template>
<script setup>

const emit = defineEmits(['consentGranted']);

const cache = useCache();

let consentValue = ref(parseInt(useCookie('cookieConsent').value ?? 0));

const showSettings = ref(consentValue.value !== 0);

const cookie0 = ref(true);
const cookie1 = ref((consentValue.value & 1) === 1);
const cookie2 = ref((consentValue.value & 2) === 2);
const cookie3 = ref((consentValue.value & 4) === 4);

const saveConsent = (value) => {

    consentValue.value = value;

    useCookie('cookieConsent', {
        maxAge: 365 * 24 * 60 * 60 // 1 year
    }).value = consentValue.value.toString();

    useConsent().setConsent(consentValue.value);

    emit('consentGranted');

    useState('cookieConsentVisible', () => false).value = false;

    if (value == 0) {
        useBaseAnalytics().pushEvent('user_interaction', {
            interaction_name: 'cookies_bar_decline_all'
        });
    }
    else if (value == 7) {
        useBaseAnalytics().pushEvent('user_interaction', {
            interaction_name: 'cookies_bar_accept_all'
        });
    }
    else {

        const settings = [];

        if ((value & 1) === 1) {
            settings.push('Analytické');
        }

        if ((value & 2) === 2) {
            settings.push('Marketingové');
        }

        if ((value & 4) === 4) {
            settings.push('Personalizované');
        }

        useBaseAnalytics().pushEvent('user_interaction', {
            interaction_name: 'cookies_bar_accept_specified',
            page_element_specification: settings.join(',')
        });
    }
}

const saveSettings = () => {
    const value = (cookie1.value && 1) | (cookie2.value && 2) | (cookie3.value && 4);
    saveConsent(value);
}

</script>
<style lang="postcss">
.cookie-consent {
    @apply fixed left-0 right-0 bottom-0 z-[1000000] py-8 border-t-2 border-primary-500 bg-neutral-50 tablet:py-6 desktop:py-16;

    .page-container {
        @apply flex flex-wrap gap-4 tablet:gap-10;
    }

    &__settings {
        @apply absolute -left-4 -right-4 top-0 bottom-0 space-y-4 max-h-0 bg-neutral-50 overflow-hidden;

        &--is-open {
            animation: slide-toggle-max-50vh-scroll 1s;
            animation-fill-mode: forwards;
            @apply top-auto p-6 border-t-2 border-primary-500 tablet:top-0 tablet:border-0 desktop:!overflow-visible;
        }
    }
}
</style>